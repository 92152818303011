var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isEdit
        ? _c("span", [_vm._v(_vm._s(_vm.labelName))])
        : _c(
            "el-select",
            {
              attrs: {
                disabled: _vm.disabled,
                value: _vm.value,
                size: _vm.size,
                filterable: "",
                placeholder: _vm.placeholder,
                clearable: _vm.clearable,
              },
              on: { change: _vm.handleChange },
            },
            _vm._l(_vm.getdata(), function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: {
                  value: item[_vm.defaultProps.value],
                  label: item[_vm.defaultProps.label],
                },
              })
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }