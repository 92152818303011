<template>
  <div>
    <span v-if="!isEdit">{{labelName}}</span>
    <el-select v-else  :disabled="disabled"  :value="value" @change="handleChange" :size="size" filterable :placeholder="placeholder" :clearable="clearable">
      <el-option v-for="(item, index) in getdata()" :key="index" :value="item[defaultProps.value]" :label="item[defaultProps.label]"></el-option>
    </el-select>
  </div>
</template>

<script>
  import * as categorys from '@/api/categorys'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'select-type',
    components: {},
    props: {
      typeId: [String, Number],
      value: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isEdit: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: 'mini'
      },
      clearable: {
        type: Boolean,
        default: true
      },
      placeholder:{
        type: String,
        default: ''
      },
      defaultProps: {
        type: Object,
        default() {
          return {
            label: 'name',
            value: 'dtCode'
          }
        }
      }
    },
    data() {
      return {
      }
    },
    destroyed() {
      this.clearTypeDatas()
    },
    computed: {
      ...mapGetters({
        typeDataLists: 'typeDataLists',
        typeIds: 'typeIds'
      }),
      getdata(){
        return function(){
          if(this.$store.state.getCategorysData.length<=0){
            return []
          }else{
            return  this.$store.state.getCategorysData.filter(u=>u.id== this.typeId)[0].categoryList
          }
         
        }
      },
      typeDatas() {
        const object = this.typeDataLists.length > 0 && this.typeDataLists.find(item => item.typeId === this.typeId)
        return object && object.typeDatas || []
      },
      labelName() {
        if(this.value==""){
          return ""
        }else{
          if(this.$store.state.getCategorysData.length<=0){
            return ""
          }else{
            let name=(this.$store.state.getCategorysData.filter(u=>u.id== this.typeId)[0].categoryList).filter((item)=>{return item.dtCode==this.value})
            if(name.length>0){
              return name[0].name
            }else{
              return "";
            }
          }
        }
      }
    },
    watch: {
      typeId() {
        this.getList()
      }
    },
    created() {
      // this.initView()
    },
    methods: {
      ...mapActions({
        saveTypeDataLists: 'saveTypeDataLists',
        saveTypeIds: 'saveTypeIds',
        clearTypeDatas: 'clearTypeDatas'
      }),
      initView() {
        const type = this.typeIds.find(item => item === this.typeId)
        this.saveTypeIds(this.typeId)
        if (type) {
          return
        }
        this.getList()
      },
      getList() {
        const listQuery = {
          page: 1,
          limit: 9999,
          TypeId: this.typeId
        }
        return categorys.getList(listQuery).then(res => {
          const obj = {
            typeId: this.typeId,
            typeDatas: res.data
          }
          this.saveTypeDataLists(obj)
        })
      },
      handleChange(val) {
        this.$emit('input', val)
      }
    }
  }

</script>

<style scoped>
</style>
